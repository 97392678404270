import React, { MouseEvent, useState } from 'react'
import {
  Button,
  Container,
  Select,
  MenuItem,
  CircularProgress,
  IconButton,
  Grid,
  Typography,
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import { DatePicker } from '@material-ui/pickers'
import { Moment } from 'moment'

import { ERRORS, STRINGS } from 'consts'
import { useApi, useApiCall } from 'hooks'
import { snackbar } from 'components'

import {
  downloadCSV,
  formatUserCSV,
  formatPrizesCSV,
  formatCSV,
  formatUserTestCSV,
  formatCouponCSV
} from './utils/parseCsv'
import { downloadUsers, downloadUserPrizes, downloadUserTest, downloadUserCoupons } from './api'
import { DownloadParams, UserParams, UserTestParams, GetUserTestResponse } from './types'
import {
  GetUsersResponse,
  GetPrizesUsersResponse,
  GetUsersVisitsResponse,
  GetCouponsUsersResponse,
} from '../Users/types'
import { GetStoresResponse, Store } from '../Stores/types'
import { getStores } from '../Stores/api'

type DataType = 'Usuarios' | 'UsuariosVisita' | 'Cupones' | 'Premios' | 'Reward' | 'UserTest'
interface Params {
  startDate: string
}

const Download: React.FC = () => {
  const [startDate, setStartDate] = useState<Moment | null>(null)
  const [endDate, setEndDate] = useState<Moment | null>(null)
  const [dataType, setDataType] = useState<DataType>('Usuarios')
  const [userSelected, setUserSelected] = useState<number>(0)
  const [storeSelected, setStoreSelected] = useState<number>(0)

  const getStoresResponseGetter = (responseData: GetStoresResponse) => responseData?.stores ?? []

  const [{ data: stores }] = useApi<GetStoresResponse, Store[]>(
    getStores,
    getStoresResponseGetter,
    {
      baseData: [],
      onError: () => snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE),
    },
  )

  const [requestUsers, isRequestingUsers] = useApiCall<UserParams, GetUsersResponse>(downloadUsers)
  const [requestUsersVisit, isRequestingUsersVisit] = useApiCall<
    UserParams,
    GetUsersVisitsResponse
  >(downloadUsers)

  const [requestUserCoupons, isRequestingUserCoupons] = useApiCall<
    DownloadParams,
    GetCouponsUsersResponse
  >(downloadUserCoupons)

  const [requestUserPrizes, isRequestingUserPrizes] = useApiCall<
    DownloadParams,
    GetPrizesUsersResponse
  >(downloadUserPrizes)

  const [requestUserTest, isRequestingUserTest] = useApiCall<UserTestParams, GetUserTestResponse>(downloadUserTest)

  const handleStartDateChange = (newDate: Moment | null) => {
    if (newDate) setStartDate(newDate.startOf('day'))
  }

  const handleEndDateChange = (newDate: Moment | null) => {
    if (newDate) setEndDate(newDate.endOf('day'))
  }

  const handleDataSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    setDataType(event.target.value as DataType)
  }

  const handleUserSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    setUserSelected(event.target.value as number)
  }

  const handleStoreSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStoreSelected(event.target.value as number)
  }

  const handleClearStart = (e: MouseEvent) => {
    e.stopPropagation()
    setStartDate(null)
  }

  const handleClearEnd = (e: MouseEvent) => {
    e.stopPropagation()
    setEndDate(null)
  }

  const handleDownload = async () => {
    if (dataType === 'Usuarios') {
      const { users: usersToCSV } = await requestUsers({
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
        download: true,
      })
      if (usersToCSV === null) { return snackbar.show(ERRORS.LIMIT_ROWS_ERROR_MESSAGE) }
      if (usersToCSV.length) return downloadCSV(formatCSV(usersToCSV), dataType)
      return snackbar.show(ERRORS.NO_DATA_ERROR_MESSAGE)
    }

    if (dataType === 'UsuariosVisita') {
      const { users: usersToCSV } = await requestUsersVisit({
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
        downloadVisit: true,
      })
      if (usersToCSV === null) { return snackbar.show(ERRORS.LIMIT_ROWS_ERROR_MESSAGE) }
      if (usersToCSV.length) return downloadCSV(formatUserCSV(usersToCSV), dataType)
      return snackbar.show(ERRORS.NO_DATA_ERROR_MESSAGE)
    }

    if (dataType === 'Cupones') {
      const { coupons: couponsToCSV } = await requestUserCoupons({
        id: userSelected,
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
        storeId: storeSelected,
      })
      if (couponsToCSV.length) return downloadCSV(formatCouponCSV(couponsToCSV), "Promo")
      return snackbar.show(ERRORS.NO_DATA_ERROR_MESSAGE)
    }

    if (dataType === 'Premios' || dataType === 'Reward') {
      const { prizes: prizesToCSV } = await requestUserPrizes({
        id: userSelected,
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
        storeId: storeSelected,
        rewardsOnly: dataType === 'Reward'
      })
      if (prizesToCSV.length) return downloadCSV(formatPrizesCSV(prizesToCSV), dataType)
      return snackbar.show(ERRORS.NO_DATA_ERROR_MESSAGE)
    }

    if (dataType === 'UserTest') {
      try {
        const { tests: testsToCSV } = await requestUserTest({
          startDate: startDate?.toISOString(),
          endDate: endDate?.toISOString(),
        })
        if (testsToCSV === null) { return snackbar.show(ERRORS.LIMIT_ROWS_ERROR_MESSAGE) }
        if (testsToCSV.length) return downloadCSV(formatUserTestCSV(testsToCSV), dataType)
        return snackbar.show(ERRORS.NO_DATA_ERROR_MESSAGE)
      } catch (error) {
        return snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE)
      }
    }

    return false
  }

  const isLoading =
    isRequestingUsers || isRequestingUsersVisit || isRequestingUserPrizes || isRequestingUserTest

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <Typography variant="h6" component="h6" style={{ marginBottom: 30 }}>
            Tipo de descarga
          </Typography>
          <Select value={dataType} onChange={handleDataSelect} fullWidth>
            <MenuItem value="Usuarios">Usuarios Registro</MenuItem>
            <MenuItem value="UsuariosVisita">Registro Visitas</MenuItem>
            <MenuItem value="Cupones">Promos</MenuItem>
            <MenuItem value="Premios">Premios</MenuItem>
            <MenuItem value="Reward">Beneficios</MenuItem>
            <MenuItem value="UserTest">User Test</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={4} />
        <Grid item xs={12} sm={4}>
          <DatePicker
            value={startDate}
            defaultValue={null}
            onChange={handleStartDateChange}
            autoOk
            orientation="landscape"
            format={STRINGS.DATE_FORMAT}
            initialFocusedDate={startDate}
            style={{ width: '100%' }}
            label="Desde"
            InputProps={{
              endAdornment: (
                <IconButton onClick={(e: MouseEvent) => handleClearStart(e)}>
                  <ClearIcon />
                </IconButton>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DatePicker
            value={endDate}
            defaultValue={null}
            onChange={handleEndDateChange}
            autoOk
            orientation="landscape"
            format={STRINGS.DATE_FORMAT}
            initialFocusedDate={endDate}
            style={{ width: '100%' }}
            label="Hasta"
            InputProps={{
              endAdornment: (
                <IconButton onClick={e => handleClearEnd(e)}>
                  <ClearIcon />
                </IconButton>
              ),
            }}
          />
        </Grid>

        {(dataType === 'Premios' || dataType === 'Reward') && (
          <Grid item xs={12} sm={8} spacing={2}>
            <h3>Usuario: </h3>
            <Select
              value={userSelected}
              onChange={handleUserSelect}
              fullWidth
              placeholder="Seleccionar usuario"
            >
              <MenuItem value={0}>Todos los usuarios</MenuItem>
            </Select>
            <h3>Tienda: </h3>
            <Select
              value={storeSelected}
              onChange={handleStoreSelect}
              fullWidth
              defaultValue={0}
              placeholder="Seleccionar tienda"
            >
              <MenuItem value={0}>Todas las tiendas</MenuItem>
              {stores.map(store => (
                <MenuItem value={store.id}>
                  {store.id} - {store.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        )}

        {dataType === 'Cupones' && (
          <Grid item xs={12} sm={8} spacing={2}>
            <h3>Usuario: </h3>
            <Select
              value={userSelected}
              onChange={handleUserSelect}
              fullWidth
              defaultValue={0}
              placeholder="Seleccionar usuario"
            >
              <MenuItem value={0}>Todos los usuarios</MenuItem>
            </Select>
            <h3>Tienda: </h3>
            <Select
              value={storeSelected}
              onChange={handleStoreSelect}
              fullWidth
              defaultValue={0}
              placeholder="Seleccionar tienda"
            >
              <MenuItem value={0}>Todas las tiendas</MenuItem>
              {stores.map(store => (
                <MenuItem value={store.id}>
                  {store.id} - {store.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        )}

        <Grid item xs={12} style={{ marginTop: 30 }}>
          <Button onClick={handleDownload} variant="contained" color="primary" disabled={isLoading}>
            Descargar{' '}
            {isLoading && (
              <CircularProgress style={{ color: 'gray', marginLeft: 10 }} thickness={5} size={20} />
            )}
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}

export { Download }
