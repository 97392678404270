const isTeaConnection = (): boolean => {
    return isAppName('tea')
}

const isAppName = (appName: string): boolean => {
    const app = process.env.REACT_APP_NAME
    return appName.toLowerCase() == app?.toLowerCase()
}

export const ConfigUtils = {
    isAppName,
    isTeaConnection
}
