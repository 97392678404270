import { ConfigUtils } from 'utils/config'

const STORE_COLORS = ConfigUtils.isTeaConnection()
  ? {
      PRIMARY_MAIN: '#27352E',
      PRIMARY_CONTRAST: '#CDCDCD',
      SECONDARY_MAIN: '#27352e',
      BACKGROUND: '#dcdcdc',
    }
  : {
      PRIMARY_MAIN: '#E78923',
      PRIMARY_CONTRAST: '#275E56',
      SECONDARY_MAIN: '#27352e',
      BACKGROUND: '#F5F6F8',
    }

export { STORE_COLORS }
