import { ConfigUtils } from 'utils/config'

const COLORS = ConfigUtils.isTeaConnection()
  ? {
      BASE: '#27352e',
      PRIMARY_BACKGROUND: 'rgb(245, 246, 248)',
      ACCENT: '#efefef',
      WHITE: '#fff',
      LIGHT_GREY: '#efefef',
      GREY: 'rgba(0, 0, 0, 0.54)',
      DARK_GREY: '#2E2E2E',
    }
  : {
      BASE: '#E78923',
      PRIMARY_BACKGROUND: 'rgb(245, 246, 248)',
      ACCENT: '#275E56',
      WHITE: '#fff',
      LIGHT_GREY: '#efefef',
      GREY: 'rgba(0, 0, 0, 0.54)',
      DARK_GREY: '#2E2E2E',
    }

export { COLORS }
