import React, { useState } from 'react'
import moment from 'moment'
import EditIcon from '@material-ui/icons/Edit'

import { ERRORS, STRINGS } from 'consts'
import { useApi, useApiCall } from 'hooks'
import { snackbar } from 'components'

import { getCoupons } from './api'
import { getUserTestTagData } from '../ManualRewards/api'
import { getActiveTags, getTotalUsersWithAllTags } from '../Tags/api'
import { DefaultTable } from '../components'
import { GetCouponsResponse, Coupon } from './types'
import { CreateCouponDialog, DeleteCouponDialog, UpdateCouponDialog } from './components'
import calculateUserTestByTags from '../Helpers/userTestHelper'
import { GetUserTestTagData, GetUserTestTagResponse } from '../ManualRewards/types'
import {
  GetTagsResponse,
  GetTotalUsersWithAllTagsPayload,
  GetTotalUsersWithAllTagsResponse,
  Tag,
} from '../Tags/types'

const onRenderTags = (item: any) => {
  if (!item.tags || !item.tags.length) return ''
  const tags = item.tags.map((t: any) => t.title)
  return tags.join(', ')
}

const columns = [
  { title: 'ID', field: 'id' },
  { title: 'Orden', field: 'order' },
  { title: 'Título', field: 'title' },
  { title: 'Descripción corta', field: 'accentDescription' },
  {
    title: 'Descripción larga',
    render: (rowData: Coupon) => rowData.description ?? STRINGS.TABLE_COLUMN_NOT_DEFINED,
    customSort: (a: Coupon, b: Coupon) => ((a.description ?? '') < (b.description ?? '') ? -1 : 1),
  },
  { title: 'Texto adicional', field: 'additionalText' },
  {
    title: 'Icono',
    render: (rowData: Coupon) => <img src={rowData.iconUrl} alt="" style={{ width: 20 }} />,
    sorting: false,
  },
  {
    title: 'Imagen',
    render: (rowData: Coupon) => <img src={rowData.imageUrl} alt="" style={{ width: 100 }} />,
    sorting: false,
  },
  {
    title: 'Vencimiento',
    render: (rowData: Coupon) => (
      <table>
        <tbody>
          <tr>
            <td
              style={{
                color: moment().isAfter(rowData.expiryDate, 'day') ? 'red' : 'black',
              }}
            >
              {moment(rowData.expiryDate).format(STRINGS.DATE_FORMAT)}
            </td>
          </tr>
        </tbody>
      </table>
    ),
    customSort: (a: Coupon, b: Coupon) =>
      !a.expiryDate || moment(a.expiryDate).isAfter(moment(b.expiryDate)) ? 1 : -1,
  },
  {
    title: 'Tags',
    render: (rowData: Coupon) => onRenderTags(rowData),
  },
  {
    title: 'Tags requeridos',
    render: (rowData: Coupon) => (rowData.allTagsAreRequired ? 'Sí' : 'No'),
    sorting: false,
  },
]

const getCouponsResponseGetter = (responseData: GetCouponsResponse) => responseData?.coupons ?? []
const getTagsResponseGetter = (responseData: GetTagsResponse) => responseData?.tags ?? []
const getUserTestTagResponseGetter = (responseData: GetUserTestTagResponse) =>
  responseData?.userTestTagData ?? []

const Coupons: React.FC = () => {
  const [{ data: coupons, isLoading }, refetchCoupons, setCoupons] = useApi<
    GetCouponsResponse,
    Coupon[]
  >(getCoupons, getCouponsResponseGetter, {
    baseData: [],
    onError: () => snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE),
  })

  const [{ data: tags }, , setTags] = useApi<GetTagsResponse, Tag[]>(
    getActiveTags,
    getTagsResponseGetter,
    { baseData: [], onError: () => snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE) },
  )

  const [{ data: userTestTagData }, , setUserTestTagData] = useApi<
    GetUserTestTagResponse,
    GetUserTestTagData[]
  >(getUserTestTagData, getUserTestTagResponseGetter, {
    baseData: [],
    onError: () => snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE),
  })

  const [isCreateCouponDialogOpen, setIsCreateCouponDialogOpen] = useState(false)
  const [isDeleteCouponDialogOpen, setIsDeleteCouponDialogOpen] = useState(false)
  const [couponToDelete, setCouponToDelete] = useState<Coupon | null>(null)
  const [isUpdateCouponDialogOpen, setIsUpdateCouponDialogOpen] = useState(false)
  const [couponToUpdate, setCouponToUpdate] = useState<Coupon | null>(null)

  const [getTotalUsersWithAllTagsApi] = useApiCall<
    GetTotalUsersWithAllTagsPayload,
    GetTotalUsersWithAllTagsResponse
  >(getTotalUsersWithAllTags)

  const handleCreateCouponDone = () => {
    refetchCoupons()
    setIsCreateCouponDialogOpen(false)
  }

  const handleDeleteCouponDialogClose = () => {
    setIsDeleteCouponDialogOpen(false)
    setCouponToDelete(null)
  }

  const handleDeleteCouponDone = () => {
    if (!couponToDelete) return
    setCoupons(coupons.filter(coupon => coupon.id !== couponToDelete.id))
    handleDeleteCouponDialogClose()
  }

  const handleUpdateCouponDialogClose = () => {
    refetchCoupons()
    setIsUpdateCouponDialogOpen(false)
    setCouponToUpdate(null)
  }

  const handleUpdateCouponDone = (updatedCoupon: Coupon) => {
    setCoupons(
      coupons.map(coupon => {
        if (coupon.id === updatedCoupon.id) {
          return updatedCoupon
        }
        return coupon
      }),
    )
    handleUpdateCouponDialogClose()
  }

  const handleCalculateUserTestByTags = async (
    tags: any[],
    allTagsAreRequired = false,
  ): Promise<number> => {
    if (!allTagsAreRequired) return calculateUserTestByTags(userTestTagData, tags)

    if (!tags.length) return 0
    const res = await getTotalUsersWithAllTagsApi({
      tagIds: tags.map(el => el.value),
    })

    return res.total
  }

  const actions = [
    {
      icon: 'add',
      tooltip: 'Nueva promo',
      isFreeAction: true,
      onClick: () => setIsCreateCouponDialogOpen(true),
    },
    {
      icon: () => <EditIcon />,
      tooltip: 'Editar promo',
      onClick: (_: React.SyntheticEvent, row: Coupon | Coupon[]) => {
        setCouponToUpdate(row as Coupon)
        setIsUpdateCouponDialogOpen(true)
      },
    },
    {
      icon: 'delete',
      tooltip: `Eliminar`,
      onClick: (_: React.SyntheticEvent, row: Coupon | Coupon[]) => {
        setCouponToDelete(row as Coupon)
        setIsDeleteCouponDialogOpen(true)
      },
    },
  ]

  return (
    <>
      <DefaultTable
        title="Promos"
        columns={columns}
        data={coupons}
        isLoading={isLoading}
        actions={actions}
      />
      {isCreateCouponDialogOpen && (
        <CreateCouponDialog
          handleClose={() => setIsCreateCouponDialogOpen(false)}
          onDone={handleCreateCouponDone}
          calcUsersByTags={handleCalculateUserTestByTags}
          tags={tags}
        />
      )}
      {isDeleteCouponDialogOpen && couponToDelete && (
        <DeleteCouponDialog
          handleClose={handleDeleteCouponDialogClose}
          couponId={couponToDelete.id}
          couponTitle={couponToDelete.title}
          onDone={handleDeleteCouponDone}
        />
      )}
      {isUpdateCouponDialogOpen && couponToUpdate && (
        <UpdateCouponDialog
          handleClose={handleUpdateCouponDialogClose}
          couponToUpdate={couponToUpdate}
          onDone={handleUpdateCouponDone}
          calcUsersByTags={handleCalculateUserTestByTags}
          tags={tags}
        />
      )}
    </>
  )
}

export { Coupons }
